@import "../global/imports";

article.project {
  header {
    margin-bottom: 30px;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: flex-end;

      .box-left {
        position: relative;
        flex: 4;

        .square {
          display: block;
          padding-bottom: 100%;
          z-index: 0;
        }

        .inner {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }

      .box-right {
        flex: 6;
      }
    }

    .box-left {
      color: $white;
      @include fadeInUp();     

      h1 {
        color: inherit;
        font-size: $h2-font-size;
      }

      .subtitle {
        font-size: 1.2rem;
        line-height: 1.2;
      }
    }

    .box-right {
      @include fadeInUp();     
      font-size: 1.065rem;
      line-height: 1.4;
    }
  }
}
