@import "../global/imports";

#projects {
  &-inner {
    position: relative;
  }

  a {
    display: block;
    position: relative;
  }

  .image {
    width: 100%;
    position: relative;

    &+.image {
      margin-top: 25px;
    }

    img {
      transition: 0.6s transform cubic-bezier(0.215, 0.610, 0.355, 1);
    }

    &:hover {
      img {
        transform: scale(1.04);
      }

      &:nth-child(odd) .skew {
        background-position: 15px center;
      }
      &:nth-child(even) .skew {
        background-position: calc(100% - 15px) center;
      }
      // .skew:after,
      // .skew:before {
      //   width: 95%;
      // }
    }

    &:nth-child(odd) {
      .skew {
        right: 0;

        // &:after {
        //   transform: skew(15deg) translateX(40%);
        //   right: 0;
        // }
        // &:before {
        //   transform: skew(15deg) translateX(calc(40% - 15px));
        //   left: 0;
        // }

        .caption {
          transform: rotate(90deg) translateY(100%);
          transform-origin: right bottom;
          right: 0;
        }
      }
    }

    &:nth-child(even) {
      .skew {
        left: 0;
        background-position: right center;

        // &:after {
        //   transform: skew(15deg) translateX(-40%);
        //   left: 0;
        // }
        // &:before {
        //   transform: skew(15deg) translateX(calc(-40% + 15px));
        //   left: 0;
        // }

        .caption {
          transform: rotate(-90deg) translateY(100%);
          transform-origin: left bottom;
          left: 0;
        }
      }
    }

    .skew {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      z-index: 1;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      background-image: url(../img/skew.png);
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);


      // &:after, &:before {
      //   content: "";
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   opacity: 1;
      //   background-color: $gray-800;
      //   z-index: 0;
      //   transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
      // }
      // &:before {
      //   background-color: $body-bg;
      //   z-index: -1;
      // }

      .caption {
        // @extend .h4;
        display: block;
        margin: 0;
        color: $white;
        position: absolute;
        bottom: 0;
        width: 75%;
        padding: 10px 15px;
        font-size: 14px;

        @include media-breakpoint-up(sm) {
          font-size: 16px;
        }

        @include media-breakpoint-up(md) {
          font-size: 22px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 30px;
          padding: 15px 20px;
        }

        h4 {
          margin-bottom: 0.2em;
          font-size: 1em;
        }

        .subtitle {
          display: none;
          line-height: 1.2;

          @include media-breakpoint-up(md) {
            display: block;
            font-size: 0.7em;
          }
        }
      }
    }
  }

}
