@import "../global/imports";


section.quote {
  .section-inner {
  }
  
  
  blockquote {
    border-top: 1px solid $gray-400;
    border-bottom: 1px solid $gray-400;
    margin: 0;
    padding: 25px 0;
    font-size: 20px;
    font-weight: 300;
    text-align: center;

    .author {
      font-size: 0.75em;
    }
  }
}
