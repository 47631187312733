@import "global/fonts";
@import "global/imports";
@import "bootstrap/bootstrap";

@import "animate/animate.scss";


@import "components/typography";
@import "components/header";
@import "components/intro";


html {
  height: 100%;
}

body {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-flow: column;
  min-height: 100%;
  max-width: 100%;
  position: relative;
  height: 100%;
  font-size: 1.08rem;

  &.no-scroll {
    position: absolute;
    overflow: hidden;
    height: 100vh;
  }
}

#footer {
  font-size: 14px;
  height: 50px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;

  .navbar {
    padding: 0;
  }
  .nav-link{
    font-size: 0.9rem;
  }
}

.alert {
  padding-top: 30px;
  padding-bottom: 30px;
}

.page {
  flex: 1;
  padding-bottom: 50px;
}

main {
  overflow: hidden;
  @include fadeIn();
}

.container {
  max-width: 1100px;
}

figure {
  margin: 0;
}

figure.image-wrapper {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    height: auto;
    transition: 1s opacity ease;
    object-fit: cover;
    display: block;
  }
}

button:focus {
  outline: none;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  @include fadeIn();
}



table {
  width: 100%;
  font-size: 1.2rem;
  background-color: $gray-100;
  border-radius: $border-radius;
  overflow: hidden;
  overflow-x: auto;
  margin-bottom: 2em;

  thead,
  .st-head-row {
    font-weight: bold;
    font-size: 1.5rem;
    border-bottom: 3px solid $body-bg;
  }

  th,
  td {
    padding: 10px 15px;
    text-align: left;
  }

  td {
    border-bottom: 1px solid $body-bg;
  }
}

.stacktable {
  .st-key {
    text-align: left;
    font-weight: 700;
  }

  &.small-only {

    th:empty,
    td:empty {
      display: none;
      ;
    }
  }
}

/*=============================================
=            Overlay Styles            =
=============================================*/

.fancybox-bg {
  background: #000;
}

.fancybox-is-open .fancybox-bg {
  opacity: 1;
}

.blocks {
  section {
    margin-top: 50px;
  }
}

video,
iframe {
  width: 100%;
  height: auto;
}

/*=============================================
=            Breadcrumbs            =
=============================================*/
.breadcrumbs {
  padding: 15px 0;

  ul {
    padding: 0;
    margin: 0;
    color: $gray-400;
    list-style: none;
    display: flex;
    justify-content: flex-start;

    li {
      padding-right: 10px;

      &:after {
        content: ">";
        display: inline-block;
        padding-left: 7px;
      }

      &:last-child:after {
        content: none;
      }

      color: $gray-400;

      a {
        color: $gray-400;
      }
    }
  }
}




