@import "../global/imports";

#contact {

  input[type=text],
  input[type=email],
  textarea {
    border: 0 none;
    -webkit-appearance: none;
    background: $white;
    border-radius: 3px;
    padding: 10px;
    font-size: 16px;
    min-width: 280px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }

  input[type=submit] {
    border: 0 none;
    background-color: $primary;
    border-radius: 3px;
    padding: 10px 25px;
  }

  .uniform__potty {
    display: none;
  }

  .field-wrapper {
    width: 100%;
    margin-bottom: 15px;

    &.inline {
      align-items: center;
      
      @include media-breakpoint-up(md) {
        display: flex;
      }

      label {
        flex: 1;
        padding-right: 10px;
      }

      .field {
        flex: 3;
      }
    }

  }

  label {
    padding: 7px 0;
    display: inline-block;
    font-weight: 400;
    margin: 0;
  }

  textarea {
    min-height: 120px;
  }

  abbr {
    text-decoration: none;
  }

  .form-actions {
    text-align: right;
  }

  input[type="submit"] {
    max-width: 130px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
    color: $white;
    -webkit-appearance: none;
    appearance: none;
    background: $link-color;
    border: 1px solid darken($link-color, 10%);

    &:hover {
      background: $link-hover-color;
      border: 1px solid darken($link-hover-color, 10%);
    }
  }

  .alert {
    padding: 5px 0;
    display: block;
    width: 100%;
    text-align: right;
  }

  .error {
    font-size: 14px;
    color: $red;
  }

  .message {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    padding: 30px;
    border-radius: 8px;

    &.error {
      color: $white;
      background: darken($red, 10%);
    }

    &.success {
      color: $white;
      background: darken($green, 10%);
    }
  }
}
