@import "global/imports";

$hmobile: 60px;
$hdesktop: 80px;

body {
  padding-top: $hmobile*1.6;

  @include media-breakpoint-up(lg) {
    padding-top: $hdesktop*1.625;
  }
}

#header {
  z-index: 100;
  position: absolute;
  top: 0;
  width: 100%;
  height: $hmobile;
  background-color: $body-bg;
  display: flex;
  align-items: center;
  transition: 0.6s transform cubic-bezier(0.075, 0.82, 0.165, 1);
  
  @include media-breakpoint-up(lg) {
    height: $hdesktop;
    // margin-top: -$hdesktop;
  }
  
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    height: 50px;
    background: $body-bg;
  }
  &:after {
    content: "";
    position: absolute;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 40px;
    background: url(../img/drop-shadow.png) no-repeat top center;
    background-size: 100% 40px;
    opacity: 0.1;
    bottom: 0;
  }
  
  &.fixed-header {
    transform: translateY($hdesktop);
    position: fixed;
    top: -$hdesktop;
    &:after {
      bottom: -20px;
    }
  }
}

.navbar {

  .navbar-brand {
    position: relative;
    z-index: 1;
    margin: 0;

    img {
      width: auto;
      max-width: none;
      margin-left: 15px;
      height: 36px;

      @include media-breakpoint-up(lg) {
        margin-left: 0;
        height: 50px;
      }
    }
  }

  .nav-link {
    font-weight: 400;
    font-size: 1rem;
    color: $dark;
    cursor: pointer;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      position: relative;

      &:after {
        content: '';
        overflow: hidden;
        position: absolute;
        left: 50%;
        bottom: -5px;
        display: block;
        width: 0;
        height: 2px;
        transform: translateX(-50%);
        opacity: 0;
        background-color: $body-color;
        transition: all .3s cubic-bezier(.175, .885, .32, 1.075);
      }
    }

    &.active,
    &:hover {
      span:after {
        width: 100%;
        opacity: 1;
      }
    }
  }

  .navbar-collapse {
    @include media-breakpoint-up(md) {
      height: auto;
      top: $hdesktop;

      .nav-item:first-child .nav-link {
        padding-left: 0;
      }
    }

    @include media-breakpoint-down(md) {
      position: fixed;
      width: 100%;
      background: $gray-900;
      left: 100vw;
      top: $hmobile;
      height: calc(100vh - #{$hmobile});
      transition: 0.4s left cubic-bezier(0.075, 0.82, 0.165, 1);

      &.show {
        left: 0;
      }

      .navbar-nav {
        padding: 30px;
        padding: 0 45px;
        height: 100%;
        transform: translateY(-$hmobile);
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
      }

      .nav-link {
        font-size: 1rem * 1.4;
        color: $gray-300;
      }
    }
  }
}

.toggler {
  border: 0 none;
  outline: none;
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;

  &:focus {
    outline: none;
  }

  &:hover {
    opacity: 0.7;
  }

  .toggler-box {
    width: 22px;
    height: 16px;
    display: inline-block;
    position: relative;

    .toggler-inner {
      display: block;
      top: 50%;
    }
  }

  .toggler-inner,
  .toggler-inner::before,
  .toggler-inner::after {
    width: 22px;
    height: 2px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  .toggler-inner::before,
  .toggler-inner::after {
    content: "";
    display: block;
  }

  .toggler-inner::before {
    top: -6px;
  }

  .toggler-inner::after {
    bottom: -6px;
  }
}

/*
   * Squeeze
   */
.toggler--squeeze {
  .toggler-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active .toggler-inner {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease,
        transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
