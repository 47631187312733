@import "../global/imports";

html {
  font-size: 0.9375rem;
}

@include media-breakpoint-up(sm) {
  html {
    font-size: 1.063rem;
  }
}

@include media-breakpoint-up(md) {
  html {
    // font-size: 1.2rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
label,
input {
  color: inherit;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  line-height: 1.2em;

}

h1,
h2,
h3 {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

h1,
.h1 {
  font-size: $h1-font-size;
  font-weight: 700;

  // @include media-breakpoint-up(sm) {
  //   font-size: $h1-font-size * 0.875;
  // }

  // @include media-breakpoint-up(md) {
  //   font-size: 2.125rem;
  // }
}

h2,
.h2 {
  font-size: $h2-font-size;

  // @include media-breakpoint-up(sm) {
  //   font-size: $h2-font-size * 0.875;
  // }

  // @include media-breakpoint-up(md) {
  //   font-size: 1.725rem;
  // }
}

h3,
.h3 {
  font-size: $h3-font-size;

  // @include media-breakpoint-up(md) {
  //   font-size: 1.425rem;
  // }
}

h4,
.h4 {
  font-size: $h4-font-size;
  font-weight: bold;
}

h5,
.h5 {
  font-size: $h5-font-size;
  font-weight: bold;
}

h6,
.h6 {}

.display-1,
.display-2,
.display-3,
.display-4 {
  hyphens: auto;
}

p+h2,
p+h3,
p+h4,
p+h5,
p+h6,
ul+h2,
ul+h3,
ul+h4,
ul+h5,
ul+h6 {
  margin-top: 1.5em;
}

button {
  padding: 10px 30px;
  text-transform: uppercase;
}

input {
  font-size: 16px;
}

.display-1 {
  line-height: 1.1;
  font-size: $display1-size*0.65;

  @include media-breakpoint-up(lg) {
    font-size: $display1-size*0.85;

  }

  @include media-breakpoint-up(xl) {
    font-size: $display1-size;
  }
}

.display-2 {
  font-size: $display2-size*0.65;

  @include media-breakpoint-up(lg) {
    font-size: $display2-size*0.85;

  }

  @include media-breakpoint-up(xl) {
    font-size: $display2-size;
  }
}


strong,
b {
  font-weight: 600;
}

:-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: pink;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: pink;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: pink;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: pink;
}


/*=============================================
=            MD Icons            =
=============================================*/
/* Rules for sizing the icon. */
.material-icons {
  &.md-18 {
    font-size: 18px;
  }

  &.md-24 {
    font-size: 24px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }

  &.md-64 {
    font-size: 64px;
  }

  /* Rules for using icons as black on a light background. */
  &.md-dark {
    color: rgba($black, 0.54);
  }

  &.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }

  /* Rules for using icons as white on a dark background. */
  &.md-light {
    color: rgba($white, 1);
  }

  &.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
}

p:last-of-type {
  margin-bottom: 0;
}