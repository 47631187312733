@import '../global/imports';

section.columns {
  .section-inner {
    margin: 0 -5px;
  }
  .flex-container {
    .column {
      padding: 5px;
    }
  }

  figure {
    img {
      transform: scale(1);
      transition: 0.6s transform cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    
    &:hover {
      img {
        transition: 0.8s transform cubic-bezier(0.215, 0.610, 0.355, 1);
        transform: scale(1.04);
      }
    }
  }
  
  
  @include media-breakpoint-up(md) {
    .flex-container {
      display: flex;

      .column {
        flex: 1;
      }
    }
  }
}
