section.banner {
  .section-inner {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -5px;

    figure {
      flex: 0 1 100%;
      margin: 5px;
      img {
        transform: scale(1);
        transition: 0.6s transform cubic-bezier(0.215, 0.610, 0.355, 1);
      }
      
      &:hover {
        img {
          transition: 0.8s transform cubic-bezier(0.215, 0.610, 0.355, 1);
          transform: scale(1.02);
        }
      }
    }
  }
}