@import "../global/imports";


.intro {
  .cover {
    position: relative;

    .skew {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-end;
      background-image: url(../img/skew.png);
      background-position: calc(100% - 15px) center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: 0.4s all cubic-bezier(0.215, 0.610, 0.355, 1);
      
      .caption {
        transform: rotate(-90deg) translateY(100%);
        transform-origin: left bottom;
        left: 0;
        display: block;
        margin: 0;
        color: $white;
        bottom: 0;
        width: 100%;
        padding: 10px 15px;
        font-size: 15px;
        margin: 0;

        @include media-breakpoint-up(sm) {
          font-size: 18px;
        }

        @include media-breakpoint-up(md) {
          font-size: 22px;
        }

        @include media-breakpoint-up(lg) {
          font-size: 30px;
          padding: 15px 20px;
        }

        h1 {
          font-size: 1em;
          margin: 0;
        }
      }
    }
  }
}
