@import "../global/imports";

.row.no-gutters {

}

.gallery-item {
  cursor: pointer;

  .inner {
    position: relative;

    a {
      display: flex;
      text-decoration: none;

      .cover {
        flex: 3;
      }

      @include media-breakpoint-up(sm) {
        display: block;
      }
    }

    .meta {
      flex: 5;
    }

    h4 {
      @include fadeInUp($duration: 0.8s, $delay: 0.4s);
    }

    .small {
      @include fadeInUp($duration: 0.8s, $delay: 0.6s);
      color: $gray-500;
      text-transform: uppercase;
      
    }
  }
}
