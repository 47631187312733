@import "../global/imports";

#workshops {
  .grid {
    display: flex;
    margin: -8px;
    flex-flow: row wrap;

    .grid-item {
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 50%;
      }
    }

    .image {
      cursor: pointer;
    }


    .workshop {
      padding: 8px;

      a {
        display: block;
      }

      figure {
        img {
          transform: scale(1);
          transition: 0.6s transform cubic-bezier(0.215, 0.610, 0.355, 1);
        }

        &:hover {
          img {
            transition: 0.8s transform cubic-bezier(0.215, 0.610, 0.355, 1);
            transform: scale(1.05);
          }
        }
      }

      h4 {
        font-size: 1rem;
        position: absolute;
        z-index: 1;
        visibility: hidden;
      }
    }
  }
}
