// /* nunito-sans-300 - latin */
// @font-face {
//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 300;
//   src: local('Nunito Sans Light'), local('NunitoSans-Light'),
//        url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }
/* nunito-sans-regular - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-italic - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: italic;
  font-weight: 400;
  src: local('Nunito Sans Italic'), local('NunitoSans-Italic'),
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* nunito-sans-700 - latin */
// @font-face {
//   font-family: 'Nunito Sans';
//   font-style: normal;
//   font-weight: 700;
//   src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
//        url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
//        url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
// }

/* nunito-sans-600 - latin */
@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: bold; //edit, former 600
  src: local('Nunito Sans SemiBold'), local('NunitoSans-SemiBold'),
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('/assets/fonts/Nunito-Sans/nunito-sans-v5-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}