section.body {
  .section-inner {
    padding-bottom: 20px;

    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }

    > figure {
      flex: 0 1 50%;
      padding: 5px;
    }
  }
}